.App {
  text-align: center;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.div_visible { visibility: visible;}
.div_hidden { visibility: hidden;}

.div_show { display: block;}
.div_hide { display: none;}

.rosary_wrap { position: relative; width: 100vw; height: auto;}
.root_wrap { 
  position:  relative;
  width: 100%;
}
#modal_div { 
    position: absolute !important;
    width: 30%;
    height: 70%;
    top: 7vw;
    left: 35%;
    overflow-y: scroll;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .5); 
    background-color: rgba(255,255,255,0.5);
    border-radius: 5px;
    z-index: 2; 
}

#modal_div::-webkit-scrollbar {
  display: none;
}

#modal_div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.otajstvo {
  margin-top: 50px;
  padding: 10px 10px;
}

.krunica {
  margin-top: 50px;
  padding: 10px 10px;
}

#modal_div p {
  position: relative;
  display: block;
  color: #6c6c6c;
  text-align: center;
}

#modal_div h3 {
  position: relative;
  display: block;
  color: #6c6c6c;
  text-align: center;
}

.close_modal {
  position: absolute;
  display: block;
  top: 0px;
  right: 0px;
  width: 32px;
  height: 32px;
  padding: 10px 10px;
}

.close_modal_down {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}

.close_modal_down a {
  position: relative;
  display: block;
  width: 40%;
  text-decoration: none;
  margin-left: 30%;
  padding: 5px 5px;
  text-align: center;
  color: #6c6c6c;
  border: 2px solid #6c6c6c;
  border-radius: 2px;
  height: auto;
}


#root { position: relative; height: 100%;}
.canvas-wrap {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-image:url(./img/clouds_2.jpg) ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}


@media screen and (max-width: 990px) {
  #modal_div { 
    position: absolute !important;
    width: 50%;
    height: 70%;
    top: 7vw;
    left: 25%;
  }
}

@media screen and (max-width: 767px) {

  #modal_div { 
    position: absolute !important;
    width: 80%;
    height: 90%;
    top: 7vw;
    left: 10%;
  }
}